import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {MiniNewsDto} from '../models/miniNewsDto';
import {InfiniteScrollItem, News} from "../models/news";
import {NewsSummary} from "../models/newsSummary";
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {ViewCount} from '../models/viewCount';
import {SitemapNewsDTO} from "../../pages/archive-sitemap-page/types/SitemapNewsDTO";
import {SitemapRangeIdentifier} from "../../pages/archive-sitemap-page/types/SitemapRangeIdentifier";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  newsSummaryWordCount = new BehaviorSubject<any>(null);
  loadedNews = new Map<number, News>();

  constructor(private http: HttpClient) { }

  getNewsById(id: number) {
    const params = new HttpParams();
    return this.http.get<News>(`${environment.apiBase}/news/${id}`, {params})
      .pipe(
        tap(news => {
          this.loadedNews.set(news.id, news);
        })
      );
  }

  getSummaryByNewsId(newsId: number){
    return this.http.get<NewsSummary>(`${environment.apiBase}/summary/getByNewsId/${newsId}`);
  }

  getNewsByTagId(tagId: number, newsCount:number, fromDate: string | null){
    let params = new HttpParams()
      .set('newsCount', `${newsCount}`);
    if(fromDate){
      params = params.set('fromDate', `${fromDate}`)
    }
    return this.http.get<MiniNewsDto[]>(`${environment.apiBase}/news/byTagId/${tagId}`, {params})
  }

  getInfiniteNewsIds(){
    return this.http.get<InfiniteScrollItem[]>(`${environment.apiBase}/news/infiniteScrollNews`);
  }

  getViewCountAndUpdate(uaNewsId: number) {
    return this.http.post<ViewCount>(`${environment.counterBase}/${uaNewsId}`, {});
  }

  setNewsWordsCount(newsId: number, wordsCount: number){
    this.newsSummaryWordCount.next({ newsId, wordsCount });
  }

  getNewsByAuthorId(authorId: number, startRow: number, rowCount: number) {
    const params = new HttpParams()
      .set('startRow', `${startRow}`)
      .set('rowCount', `${rowCount}`);
    return this.http.get<MiniNewsDto[]>(`${environment.apiBase}/news/getByAuthorId/${authorId}`, {params});
  }

  getArticleCountByAuthorId(authorId: number) {
    return this.http.get<number>(`${environment.apiBase}/news/articleCountByAuthorId/${authorId}`);
  }

  getSitemapNewsByDate(date: string, lang: string) {
    date = date.replace('/00/', '/01/');
    const params = new HttpParams()
      .set('date', `${date}`)
      .set('lang', `${lang}`);
    return this.http.get<SitemapNewsDTO[]>(`${environment.apiBase}/HTMLSitemap/`, { params });
  }

  getSitemapNewsByRange(range: SitemapRangeIdentifier, lang: string) {
    const params = new HttpParams()
      .set('range', `${range}`)
      .set('lang', `${lang}`);

    return this.http.get<SitemapNewsDTO[]>(`${environment.apiBase}/HTMLSitemap/`, { params });
  }

  getNewsInfo(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiBase}/news/getInfoByNewId/${id}`);
  }
}
